<template src="./success.html"></template>

<script>
import header from '../header/header';
export default {
  name: 'VendingMachineSuccess',
  components: {
    recommendationHeader: header,
  },
  data() {
    return {};
  },
  computed: {
    product() {
      return this.$store.state.vm.selectedProductVm;
    },
  },
  created() {},
  methods: {
    imageError(e) {
      e.target.src = '/static/img/mask-station/default-image-product.png';
    },
  },
};
</script>

<style lang="scss" scoped>
@import 'success';

@media (max-width: 900px) {
  .home-page .main-section .promotion-product {
    padding: 0px 6.7037vw 30px;
  }
  .home-page .main-section .promotion-product ul .col-md-6 {
    width: 50%;
  }
}
</style>
